import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEOHeaders = (props) => {
  const onLocalHost = useMemo(() => {
    return window.location.hostname.includes('localhost');
  }, []);

  const websiteURL = useMemo(() => {
    return `${window.location.protocol}//${window.location.hostname}`;
  }, []);

  const imageUrl = useMemo(() => {
    // if the image is a full url, return it
    if(props.image.includes('http')) return props.image;
    // if we are on localHost, return the relative path
    if(onLocalHost) return props.image;

    // otherwise, return the full url
    return `${websiteURL}${props.image}`;
  }, [onLocalHost, props.image, websiteURL]);

  const imageLargeUrl = useMemo(() => {
    // if the largeImage is undefined, return the imageUrl
    if(!props.imageLarge) return imageUrl;
    // if the largeImage is a full url, return it
    if(props.imageLarge.includes('http')) return props.imageLarge;

    // if we are on localHost, return the relative path
    if(onLocalHost) return props.imageLarge;

    // otherwise, return the full url
    return `${websiteURL}${props.imageLarge}`;
  }, [props.imageLarge, imageUrl, onLocalHost, websiteURL]);

  return (
    <Helmet>
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="author" content={props.author} />

      <title>{props.title}</title>
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={imageUrl} />

      <meta name="twitter:card" content={imageLargeUrl} />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  )
};

SEOHeaders.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  imageLarge: PropTypes.string,
  author: PropTypes.string,
};

SEOHeaders.defaultProps = {
  title: 'Cornelio\'s Steakhouse',
  description: 'This is the base template for the PFS web development service',
  keywords: 'website, hosting, development, template',
  image: 'https://corneliossteakhouse.blob.core.windows.net/seo/share_card.jpg',
  imageLarge: undefined,
  author: 'Cornelio\'s Steakhouse',
};

export default SEOHeaders;
