import React, { useContext } from 'react';
import styles from './PrivacyPage.module.css';
import { ContentContainer, PanelSection } from '@pfstechnology/pfs-templates-core';
import SEOHeaders from '../SEOHeaders/SEOHeaders';
import ThemeContext from '../../contexts/ThemeContext';

const PrivacyPage = () => {
  const {
    primaryFont,
    secondaryFont,
    blackColor,
    testimonialColors: { cardBackgroundColor }
  } = useContext(ThemeContext);

  const headerStyle = {
    fontFamily: primaryFont,
    color: blackColor,
  }

  const paragraphStyle = {
    fontFamily: secondaryFont,
    color: blackColor,
  }

  return (
    <div className={styles.PrivacyPage} data-testid="PrivacyPage">
      <SEOHeaders
        title="Privacy Policy - Cornelio's Steakhouse"
        description="Privacy Policy"
        keywords="privacy, policy"
      />
      <ContentContainer>
        <PanelSection backgroundColor={cardBackgroundColor} className={styles.PanelSection} >
          <h1 style={headerStyle}>Privacy Policy</h1>
          <p style={paragraphStyle}>
            <b>1. Information Collection</b><br />
            We may collect personal information, such as names, email addresses, and contact details, when voluntarily submitted by our users. We also collect non-personal information, such as browser type and IP addresses, for analytical purposes.
          </p>
          <p style={paragraphStyle}>
            <b>2. Use of Information</b><br />
            We use the collected information to provide and improve our services, communicate with users, and personalize their experience. We may also use aggregated and anonymized data for statistical and analytical purposes.
          </p>
          <p style={paragraphStyle}>
            <b>3. Information Sharing</b><br />
            We do not sell, trade, or otherwise transfer personal information to third parties without user consent. However, we may share information with trusted service providers who assist us in operating our website and conducting our business, subject to confidentiality obligations.
          </p>
          <p style={paragraphStyle}>
            <b>4. Security Measures</b><br />
            We implement reasonable security measures to protect the confidentiality and integrity of the information collected. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>
          <p style={paragraphStyle}>
            <b>5. Cookies</b><br />
            This website uses cookies to enhance user experience and track usage patterns. Users may choose to disable cookies in their browser settings, but this may limit certain functionalities of the website.
          </p>
          <p style={paragraphStyle}>
            <b>6. Changes to the Privacy Policy</b><br />
            We reserve the right to modify this privacy policy at any time. Users will be notified of significant changes through a notice on our website.
          </p>
        </PanelSection>
      </ContentContainer>
    </div>
  )
};

export default PrivacyPage;
