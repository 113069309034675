import React, { useEffect, useState } from 'react';
import {
  ContentContainer,
  ImageLinkCard,
} from '@pfstechnology/pfs-templates-core';
import styles from './HomeServicesSection.module.css';
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import { BrowserRoutes } from '../../constants/BrowserRoutes';

import tomahawkSteak from './TomahawkSteak.jpg';
import grilledSalmon from './GrilledSalmon.jpg';
import filetMignon from './FiletMignon.jpg';

const cardsData = [
  {
    image: tomahawkSteak,
    title: 'Tomahawk Steak',
    url: `${BrowserRoutes.MENU}`,
  },
  {
    image: filetMignon,
    title: 'Filet Mignon',
    url: `${BrowserRoutes.MENU}`,
  },
  {
    image: grilledSalmon,
    title: 'Grilled Salmon',
    url: `${BrowserRoutes.MENU}`,
  },
];

const HomeServicesSection = () => {
  const {
    primaryFont,
    secondaryFont,
    blackColor
  } = useContext(ThemeContext);

  const [webUrl, setWebUrl] = useState(null);

  useEffect(() => {
    const webProtocol = window.location.protocol;
    const webHost = window.location.host;
    setWebUrl(`${webProtocol}//${webHost}`);
  }, []);
  
  const primaryFontStyle = {
    fontFamily: primaryFont,
    color: blackColor
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
    color: blackColor
  };

  return (
    <ContentContainer>
      <div
        data-testid="HomeServicesSection"
        className={styles.HomeServicesSection}
      >
        <div className={styles.header}>
          <h4 style={primaryFontStyle}>Our Menu</h4>
          <p style={secondaryFontStyle}>
            Discover the artistry of flavor at Our Menu, where every dish tells a story. From juicy, melt-in-your-mouth cuts to exquisite seafood options and vegetarian delights, our culinary creations are thoughtfully curated to cater to every palate.
          </p>
        </div>
        <div className={styles.cards} style={primaryFontStyle}>
          {cardsData.map((card, index) => (
            <ImageLinkCard
              key={index}
              {...card}
              url={`${webUrl}${card.url}`}
              className={styles.imageCards}
            />
          ))}
        </div>
      </div>
    </ContentContainer>
  );
};

export default HomeServicesSection;
