import React, { useContext } from 'react';
import styles from './GoogleMapEmbed.module.css';
import { PanelSection } from '@pfstechnology/pfs-templates-core';
import ThemeContext from '../../contexts/ThemeContext';

const GoogleMapEmbed = () => {
  const mapKey = process.env.REACT_APP_GMAPS_KEY;
  const {
    mapParams: { query, zoom },
  } = useContext(ThemeContext);
  
  const mapUrl = new URL(`https://www.google.com/maps/embed/v1/place?key=${mapKey}&q=${query}&zoom=${zoom}`);

  return (
    <div className={styles.GoogleMapEmbed} data-testid={'GoogleMapEmbed'}>
      <PanelSection className={styles.mapContainer} shadow={false}>
        <iframe
          title={'Google Map Embed'}
          style={{ border: 0 }}
          loading={'lazy'}
          src={mapUrl}
          data-testid="google-maps-iframe"
        ></iframe>
      </PanelSection>
    </div>
  );
};

export default GoogleMapEmbed;
