import React from 'react';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import AboutPage from '../AboutPage/AboutPage';
import HomePage from '../HomePage/HomePage';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TemplateLayout from '../TemplateLayout/TemplateLayout';
import { BrowserRoutes } from '../../constants/BrowserRoutes';
import PrivacyPage from '../PrivacyPage/PrivacyPage';
import TermsPage from '../TermsPage/TermsPage';

const routerConfig = createBrowserRouter([
  {
    path: BrowserRoutes.HOME,
    element: <TemplateLayout />,
    children: [
      {
        path: BrowserRoutes.HOME,
        element: <HomePage />
      },
      {
        path: BrowserRoutes.MENU,
        element: <AboutPage />
      },
      {
        path: BrowserRoutes.PRIVACY_POLICY,
        element: <PrivacyPage />,
      },
      {
        path: BrowserRoutes.TERMS_OF_SERVICE,
        element: <TermsPage />,
      },
      {
        path: BrowserRoutes.NOTFOUND,
        element: <NotFoundPage />
      }
    ]
  },
])

const TemplateRoutes = () => (
  <RouterProvider router={routerConfig} />
);

export default TemplateRoutes;
