import React from 'react';
import { Outlet } from 'react-router';
import { useGoogleTracking } from '../../hooks/useGoogleTracking';
import { useScrollToAnchor } from '../../hooks/useScrollToAnchor';
import TemplateFooter from '../TemplateFooter/TemplateFooter';
import TemplateHeader from '../TemplateHeader/TemplateHeader';
import styles from './TemplateLayout.module.css';

const TemplateLayout = () => {
  useGoogleTracking();
  useScrollToAnchor();

  return (
  <div className={styles.TemplateLayout} data-testid="TemplateLayout">
    <TemplateHeader />
    <Outlet />
    <TemplateFooter />
  </div>
)};

export default TemplateLayout;