import React, { useContext } from 'react';
import HomeBanner from '../HomeBanner/HomeBanner';
import HomeServicesSection from '../HomeServicesSection/HomeServicesSection';
import {
  BusinessNeedPanel,
} from '../BusinessPanels/BusinessPanels';
import SEOHeaders from '../SEOHeaders/SEOHeaders';
import GoogleMapEmbed from '../GoogleMapEmbed/GoogleMapEmbed';
import { ContentContainer, PanelSection } from '@pfstechnology/pfs-templates-core';
import ThemeContext from '../../contexts/ThemeContext';

import styles from './HomePage.module.css';
import ContactHighlightCards from '../ContactHighlightCards/ContactHighlightCards';
import { Link } from 'react-router-dom';

export const ReserveTableButton = () => {
  const { blackColor, secondaryColor, testimonialColors, secondaryFont } = useContext(ThemeContext);
  const { cardImageBorderColor } = testimonialColors;

  return (
    <Link
      className={styles.ReserveTableButton}
      to='https://tables.toasttab.com/restaurants/be7f2685-b2e0-4a9d-875d-743711e79f71/findTime'
      style={{
        color: blackColor,
        backgroundColor: secondaryColor,
        border: `1px solid ${cardImageBorderColor}`,
        fontFamily: secondaryFont,
      }}
    >
      Reserve a Table
    </Link>
  );
};

export const GiftCardButton = () => {
  const { blackColor, secondaryColor, testimonialColors, secondaryFont } = useContext(ThemeContext);
  const { cardImageBorderColor } = testimonialColors;

  return (
    <Link
      className={styles.ReserveTableButton}
      to='https://www.toasttab.com/cornelios-steak-house-n-a/giftcards'
      style={{
        color: blackColor,
        backgroundColor: secondaryColor,
        border: `1px solid ${cardImageBorderColor}`,
        fontFamily: secondaryFont,
      }}
    >
      Buy a Gift Card
    </Link>
  );
};

const HomePage = () => {
  const {
    primaryColor,
    primaryFont,
    secondaryFont,
    whiteColor,
  } = useContext(ThemeContext);

  const whiteColorStyle = {
    color: whiteColor,
  }

  const contactHeaderStyle = {
    ...whiteColorStyle,
    fontFamily: primaryFont,
  }

  const contactTextStyle = {
    ...whiteColorStyle,
    fontFamily: secondaryFont,
  };

  const contactTextParagraphStyle = {
    ...contactTextStyle,
    width:'80%',
    margin: '0 auto 1em',
  }

  return (
    <div data-testid="HomePage">
      <SEOHeaders
        title="Home Page - Cornelio's Steakhouse"
        description="Indulge in the finest steaks and experience culinary excellence at Cornelio's Steakhouse. Our steakhouse offers succulent cuts prepared with precision, served with passion, and accompanied by warm ambiance and impeccable service."
        keywords="steakhouse, finest steaks, dining experience, culinary excellence, succulent cuts, warm ambiance, impeccable service"
      />
      <HomeBanner />
      <BusinessNeedPanel title="About Us">
        <span>
          At <b>Cornelio's Steakhouse</b>, our passion for exceptional dining experiences is matched only by our love for the perfect steak.
        </span>
        <br /><br />
        <span>
          Founded with a vision to redefine steakhouse dining, we have curated a menu that showcases prime cuts sourced from the finest suppliers,
          meticulously aged and expertly prepared by our talented chefs.
        </span>
        <br /><br />
        <span>
          Our commitment to quality extends beyond the plate, as we strive to
          create a warm and inviting atmosphere where every guest feels like a cherished part of our steakhouse family.
        </span>
        <br /><br />
        <span>
          With a dedication to impeccable service, a discerning wine selection, and a commitment to culinary excellence, we invite you to join us on a culinary journey that celebrates the art of steak.
        </span>
        <br /><br /><br /><br />
        <ReserveTableButton />
        <br />
        <GiftCardButton />
      </BusinessNeedPanel>
      <HomeServicesSection />
      <ContentContainer>
        <PanelSection backgroundColor={primaryColor} className={styles.ContactPanel}>
          <h3 id='contact' style={contactHeaderStyle}>Contact Us</h3>
          <p style={contactTextParagraphStyle}>At Cornelio's Steakhouse, our dedicated team is ready to make your dining experience extraordinary. Whether it's a reservation, private event inquiry, or feedback you'd like to share, we're just a call or email away.</p>

          <div style={contactTextStyle} className={styles.ContactDetails}>
            <table>
              <thead>
                <tr>
                  <th>Days</th>
                  <th>Hours</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sunday - Thursday</td>
                  <td>11:00 AM - 09:00 PM</td>
                </tr>
                <tr>
                  <td>Friday & Saturday</td>
                  <td>11:00 AM - 10:00 PM</td>
                </tr>
              </tbody>
            </table>
          </div>
          <GoogleMapEmbed />
        </PanelSection>
      </ContentContainer>
      <ReserveTableButton />
      <ContactHighlightCards />
    </div>
  );
};
export default HomePage;
