import React from 'react';
import {
  ContentContainer,
  HeroSection,
} from '@pfstechnology/pfs-templates-core';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import steakGrill from './steak.jpg';
import { BrowserRoutes } from '../../constants/BrowserRoutes';
import BottomWaveSvg from './BottomWaveSvg';
import styles from './HomeBanner.module.css';

const HomeBanner = () => {
  const {
    blackColor,
    secondaryColor,
    whiteColor,
    primaryColor,
    primaryFont,
    secondaryFont,
  } = useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont
  }

  const secondaryFontStyle = {
    fontFamily: secondaryFont
  }

  const getStartedLinkProps = {
    style: {
      ...secondaryFontStyle,
      color: blackColor,
      backgroundColor: secondaryColor,
      border: 'none',
    },
    to: `${BrowserRoutes.HOME}#about`,
  };

  const contactLinkProps = {
    style: {
      ...secondaryFontStyle,
      color: whiteColor,
      border: `1px solid ${whiteColor}`,
    },
    to: BrowserRoutes.MENU,
  };
  return (
    <div
      style={{ backgroundColor: primaryColor }}
      className={styles.HomeBanner}
      data-testid="HomeBanner"
    >
      <ContentContainer>
        <HeroSection
          contentWidthPercent={40}
          contentSection={
            <div className={styles.InfoContainer} style={{ color: whiteColor }}>
              <h1 style={primaryFontStyle}>Indulge in the Finest Steaks</h1>
              <p style={secondaryFontStyle}>
                Welcome to Cornelio's Steakhouse, where exceptional steaks take center stage. 
                Indulge in tantalizing cuts, expertly prepared and passionately served.
              </p>
              <div className={styles.ButtonContainer}>
                <Link {...getStartedLinkProps}>About Us</Link>
                <Link {...contactLinkProps}>Our Menu</Link>
              </div>
            </div>
          }
          imageSection={<img src={steakGrill} alt="steak on a grill" />}
        />
      </ContentContainer>
      <div id='about'>
        <BottomWaveSvg color={primaryColor} />
      </div>
    </div>
  );
};

export default HomeBanner;
