import React, { useContext } from 'react';
import { ContentContainer, HeroSection } from '@pfstechnology/pfs-templates-core';
import steakPlate from './steak.jpg';
import styles from './AboutBanner.module.css';
import ThemeContext from '../../contexts/ThemeContext';
import { ReserveTableButton } from '../HomePage/HomePage';

const AboutBanner = () => {
  const {
    primaryFont,
    secondaryFont,
    blackColor
  } = useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
    color: blackColor
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
    color: blackColor
  };

  return (
    <div className={styles.AboutBanner} data-testid="AboutBanner">
      <ContentContainer>
        <HeroSection
          contentWidthPercent={30}
          contentSection={
            <div className={styles.content}>
              <h4 style={primaryFontStyle}>An Exquisite Culinary Symphony</h4>
              <p style={secondaryFontStyle}>
                Prepare your taste buds for an unforgettable symphony of flavors at Cornelio's Steakhouse. Our meticulously crafted menu showcases the finest selection of prime cuts, thoughtfully paired with locally sourced ingredients and expertly prepared by our passionate chefs. Explore our menu and savor the symphony of flavors that awaits.
              </p>
              
              <ReserveTableButton />
            </div>
          }
          imageSection={<img src={steakPlate} alt="steak with a side of fries" />}
        />
      </ContentContainer>
    </div>
  );
};

export default AboutBanner;
