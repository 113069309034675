import React from 'react';
import styles from "./HomeBanner.module.css"

const BottomWaveSvg = ({ color }) => {
  return (
      <svg
      className={styles.wave}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 324.207 58.536"
        style={{zIndex: -1}}
      >
        <g transform="translate(28.705 -97.838)">
          <path
            data-testid="wavebottom"
            fill={color || '#153459'}
            fillOpacity="1"
            strokeWidth="0.265"
            d="M295.502 97.85v41.997s-14.997-12.299-66.381-12.223c-51.384.076-96.505 8.697-96.505 8.697S57.7 151.197 30.09 153.444c-27.612 2.247-58.795 2.93-58.795 2.93V97.838z"
          ></path>
        </g>
      </svg>
  );
};

export default BottomWaveSvg;
