import React, { useContext } from 'react';
import { HighlightCard } from '@pfstechnology/pfs-templates-core';
import styles from './ContactHighlightCards.module.css';
import ThemeContext from '../../contexts/ThemeContext';
import { CallIcon, MailIcon, LocationIcon } from './CardsIconsSvg';

const ContactHighlightCards = () => {
  const {
    primaryFont,
    secondaryFont,
    testimonialColors: { cardBackgroundColor },
    phoneNumber,
    email,
    address,
    blackColor,
  } = useContext(ThemeContext);
  const panelProps = {
    backgroundColor: cardBackgroundColor,
    shadow: false,
  };
  const primaryFontStyle = {
    fontFamily: primaryFont,
    color: blackColor
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
    color: blackColor
  };

  const cardsData = [
    {
      title: 'Call Us',
      description: phoneNumber,
      image: <CallIcon backgroundColor={blackColor} />,
    },
    {
      title: 'Message Us',
      description: email,
      image: <MailIcon backgroundColor={blackColor} />,
    },
    {
      title: 'Visit Us',
      description: address,
      image: <LocationIcon backgroundColor={blackColor} />,
    },
  ];

  return (
    <div className={styles.cardWrapper} data-testid="ContactHighlightCards">
      {cardsData.map((card, index) => (
        <HighlightCard
          key={index}
          panelProps={panelProps}
          className={styles.card}
          image={card.image}
          aligned="left"
          title={<span style={primaryFontStyle}>{card.title}</span>}
          description={
            <span style={secondaryFontStyle}>{card.description}</span>
          }
        />
      ))}
    </div>
  );
};

export default ContactHighlightCards;
