import React, { useContext } from 'react';
import styles from './NotFoundPage.module.css';
import SEOHeaders from '../SEOHeaders/SEOHeaders';
import { Link } from 'react-router-dom';
import { ContentContainer, HeroSection, PanelSection } from '@pfstechnology/pfs-templates-core';
import ThemeContext from '../../contexts/ThemeContext';

import magnifyingGlass from './magnifyingGlass.svg'

const NotFoundPage = () => {
  const {
    primaryFont,
    secondaryFont,
    secondaryColor,
    blackColor,
    testimonialColors: { cardBackgroundColor },
  } = useContext(ThemeContext);

  const goHomeLinkStyle = {
    fontFamily: secondaryFont,
    backgroundColor: secondaryColor,
    color: blackColor
  }

  return (
    <div className={styles.NotFoundPage} data-testid="NotFoundPage">
      <SEOHeaders
        title="404 - Page Not Found"
        description="The page you are looking for does not exist."
        keywords="404, page not found, error"
      />
      <ContentContainer>
        <HeroSection
          contentWidthPercent={61}
          contentSection={
            <PanelSection backgroundColor={cardBackgroundColor} className={styles.PanelContainer}>
              <h1 style={{ fontFamily: primaryFont }}>Page Not Found</h1>
              <p style={{ fontFamily: secondaryFont }}>Sorry, that page does not exist.</p>
              <p style={{ fontFamily: secondaryFont }}>Please check the URL or return to the homepage</p>
              <Link style={goHomeLinkStyle} className={styles.GoHomeButton} to="/">Homepage</Link>
            </PanelSection>
          }
          imageSection={<img className={styles.NotFoundImage} src={magnifyingGlass} alt="magnifying glass" />}
          inverseLayout={true}
        />
      </ContentContainer>
    </div>
  )
};

export default NotFoundPage;
