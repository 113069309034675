import { createContext } from 'react';
import logo from './logo.svg';
import logoWhite from './logoWhite.svg';

export const themeDefaults = {
  primaryFont: 'Poppins, sans-serif',
  secondaryFont: 'Roboto, sans-serif',
  primaryColor: '#2476FE',
  secondaryColor: '#FFA412',
  whiteColor: 'white',
  blackColor: 'black',
  businessName: 'Business Name',
  phoneNumber: '555-555-5555',
  email: 'contact@example.com',
  address: 'Address city number state, country',
  headerTitle: <></>,
  whiteLogo: logoWhite,
  coloredLogo: logo,
  socialMediaLinks: {
    linkedin: 'https://www.linkedin.com/company/pfsglobal1',
    facebook: 'https://www.facebook.com/PFSglobal1/',
    instagram: 'https://www.instagram.com/pfsglobal1/',
    twitter: 'https://twitter.com/PFSglobal1',
  },
  testimonialColors: {
    cardBackgroundColor: '#F2F4FA',
    cardImageBorderColor: '#DEE0EA',
    cardDividerColor: '#000000',
    carouselNavColor: '#E4E6EC',
  },
  mapParams: {
    query: 'place_id:ChIJRzgR4Fgow4AR2ttip8APm18',
    zoom: 15
  }
};

const ThemeContext = createContext(themeDefaults);

export default ThemeContext;
