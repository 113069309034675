import React, { useContext } from 'react';
import styles from './TemplateFooter.module.css';
import { BusinessLogo, ContentContainer, PanelSection } from '@pfstechnology/pfs-templates-core';
import ThemeContext from '../../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { BrowserRoutes } from '../../constants/BrowserRoutes';
import { CallIcon, MailIcon } from './FooterIconsSvg';
// import { CallIcon, FacebookIcon, InstagramIcon, LinkedinIcon, MailIcon, TwitterIcon } from './FooterIconsSvg';

const TemplateFooter = () => {
  const {
    whiteLogo,
    blackColor,
    whiteColor,
    secondaryColor,
    businessName,
    phoneNumber,
    email,
    secondaryFont,
    primaryFont,
    // socialMediaLinks,
  } = useContext(ThemeContext);

  // const {
  //   linkedin,
  //   facebook,
  //   instagram,
  //   twitter,
  // } = socialMediaLinks;

  const primaryFontStyle = {
    fontFamily: primaryFont
  };

  const textStyle = {
    color: whiteColor,
    fontFamily: secondaryFont
  }

  const navLinkStyle = {
    ...textStyle,
    opacity: 0.5,
  }

  const panelProps = {
    className: styles.FooterPanel,
    backgroundColor: blackColor,
    shadow: false,
  }

  const contactLinkProps = {
    style: {
      color: blackColor,
      backgroundColor: secondaryColor,
      border: 'none',
    },
    to: `${BrowserRoutes.HOME}#contact`,
  };

  const serviceLinkProps = {
    style: {
      color: whiteColor,
      border: `1px solid ${whiteColor}`
    },
    to: BrowserRoutes.MENU,
  };

  return (
    <div className={styles.TemplateFooter} data-testid="TemplateFooter">
      <PanelSection {...panelProps} >
        <ContentContainer>
          <div className={styles.FooterContent} style={textStyle}>
            <center>
              <h3 className={styles.FooterTitle} style={primaryFontStyle}>Still have questions?</h3>
              <p className={styles.FooterSubtitle}>Here are more ways to discover how we can help you.</p>
              <div className={styles.BtnContainer}>
                <Link {...contactLinkProps}>Contact Us</Link>
                <Link {...serviceLinkProps} >Our Menu</Link>
              </div>
            </center>
          </div>
          <div className={styles.FooterNavLinks} style={textStyle}>
            <div className={styles.FooterNavColumn}>
              <h4 style={primaryFontStyle}>Get in touch</h4>
              <ul>
                <li><Link style={navLinkStyle} to={`tel:${phoneNumber}`}><CallIcon backgroundColor={whiteColor} /> {phoneNumber}</Link></li>
                <li><Link style={navLinkStyle} to={`mailto:${email}`}><MailIcon backgroundColor={whiteColor} /> {email}</Link></li>
              </ul>
            </div>
            <div className={styles.FooterNavColumn}>
              <h4 style={primaryFontStyle}>Company</h4>
              <ul>
                <li><Link style={navLinkStyle} to={BrowserRoutes.HOME}>Home</Link></li>
                <li><Link style={navLinkStyle} to={BrowserRoutes.MENU}>Menu</Link></li>
              </ul>
            </div>
            {
              /*
              <div className={styles.FooterNavColumn}>
                <h4 style={primaryFontStyle}>Follow Us</h4>
                <ul className={styles.socialIcons}>
                  <li><Link target={'_blank'} title='Our LinkedIn' style={navLinkStyle} to={linkedin}><LinkedinIcon backgroundColor={whiteColor}/></Link></li>
                  <li><Link target={'_blank'} title='Our Facebook' style={navLinkStyle} to={facebook}><FacebookIcon backgroundColor={whiteColor}/></Link></li>
                  <li><Link target={'_blank'} title='Our Instagram' style={navLinkStyle} to={instagram}><InstagramIcon backgroundColor={whiteColor}/></Link></li>
                  <li><Link target={'_blank'} title='Our Twitter' style={navLinkStyle} to={twitter}><TwitterIcon backgroundColor={whiteColor}/></Link></li>
                </ul>
              </div>
              */
            }

          </div>
          <div className={styles.FooterBranding} >
            <div style={textStyle} >© {businessName}</div>
            <div className={styles.FooterLogoContainer}>
              <Link title='Our Logo - Home page' className={styles.FooterLogo} to={BrowserRoutes.HOME}>
                <BusinessLogo img={whiteLogo} size={15} />
              </Link>
            </div>
            <div style={textStyle}>
              <Link to={BrowserRoutes.PRIVACY_POLICY} style={textStyle}>Privacy Policy</Link> | {' '}
              <Link to={BrowserRoutes.TERMS_OF_SERVICE} style={textStyle}>Terms of Service</Link>
            </div>
          </div>
        </ContentContainer>
      </PanelSection>
    </div>
  )
};

export default TemplateFooter;
