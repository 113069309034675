// Creating & exporting a constant config object in case
// we eventually need to import this and instantiate a 
// `new Rollbar` object to log errors outside of REACT hooks.
export const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    environment: process.env.REACT_APP_ROLLBAR_ENV || "development",
    captureUncaught: true,
    captureUnhandledRejections: true,
};

// For Example:
// 
// ```js
// import { rollbarConfig } from './constants/rollbarConfig';
// const rollbar = new Rollbar(rollbarConfig);
// rollbar.error("Something went wrong!");
// ```