import React, { useContext } from 'react';
import styles from './TermsPage.module.css';
import SEOHeaders from '../SEOHeaders/SEOHeaders';
import { ContentContainer, PanelSection } from '@pfstechnology/pfs-templates-core';
import ThemeContext from '../../contexts/ThemeContext';

const TermsPage = () => {
  const {
    primaryFont,
    secondaryFont,
    blackColor,
    testimonialColors: { cardBackgroundColor }
  } = useContext(ThemeContext);

  const headerStyle = {
    fontFamily: primaryFont,
    color: blackColor,
  }

  const paragraphStyle = {
    fontFamily: secondaryFont,
    color: blackColor,
  }

  return (
    <div className={styles.TermsPage} data-testid="TermsPage">
      <SEOHeaders
        title="Terms of Service - Cornelio's Steakhouse"
        description="Terms of Service for Cornelio's Steakhouse"
        keywords="terms, terms of service"
      />
      <ContentContainer>
        <PanelSection backgroundColor={cardBackgroundColor} className={styles.PanelSection}>
          <h1 style={headerStyle}>Terms of Service</h1>
          <p style={paragraphStyle}>
            <b>1. Acceptance of Terms</b><br />
            By accessing and using this website, you agree to be bound by these terms of service and all applicable laws and regulations.
          </p>
          <p style={paragraphStyle}>
            <b>2. Use of Website</b><br />
            You may use this website for lawful purposes and in compliance with these terms of service. You agree not to engage in any activities that may interfere with the proper functioning of the website or infringe upon the rights of others.
          </p>
          <p style={paragraphStyle}>
            <b>3. Intellectual Property</b><br />
            All content and materials on this website, including but not limited to text, graphics, logos, and images, are protected by intellectual property laws. You may not reproduce, modify, distribute, or display any content without prior written consent.
          </p>
          <p style={paragraphStyle}>
            <b>4. Limitation of Liability</b><br />
            We strive to provide accurate and up-to-date information on this website, but we make no warranties or representations regarding the accuracy, completeness, or reliability of the content. We shall not be liable for any direct or indirect damages arising from your use of this website.
          </p>
          <p style={paragraphStyle}>
            <b>5. Third-Party Websites</b><br />
            This website may contain links to third-party websites for your convenience. We do not endorse or control the content of these websites and shall not be responsible for any damages or losses incurred from their use.
          </p>
          <p style={paragraphStyle}>
            <b>6. Governing Law and Jurisdiction</b><br />
            These terms of service shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising from the use of this website shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].
          </p>
        </PanelSection>
      </ContentContainer>
    </div>
  )
};

export default TermsPage;
