import React, { useContext } from 'react';
import styles from './AboutPage.module.css';
import AboutBanner from '../AboutBanner/AboutBanner';
import SEOHeaders from '../SEOHeaders/SEOHeaders';
import { ContentContainer } from '@pfstechnology/pfs-templates-core';
import ThemeContext from '../../contexts/ThemeContext';

import menuOne from './Menu1.png';
import menuTwo from './Menu2.png';
import menuThree from './Menu3.png';
import menuFour from './Menu4.png';

function useStyles() {
  const {
    primaryFont,
    secondaryFont,
  } = useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const headerStyle = {
    ...primaryFontStyle,
    textAlign: 'center',
  }

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
  };

  return {
    primaryFontStyle,
    headerStyle,
    secondaryFontStyle,
  }
};

// function MenuItem({ name, description, price }) {
//   const { primaryFontStyle, secondaryFontStyle } = useStyles();

//   return (
//     <div>
//       <h4 style={primaryFontStyle}>{name}</h4>
//       <p style={secondaryFontStyle}>{description}</p>
//       <p><i>{price ? `$${price}` : 'Price upon request'}</i></p>
//     </div>
//   );
// }

// function MenuCategory({ category, items }) {
//   const { headerStyle } = useStyles();

//   return (
//     <div className={styles.MenuCategory}>
//       <h3 style={headerStyle}>{category}</h3>
//       <PanelSection className={styles.MenuItemsPanel}>
//         {items.map(item => <MenuItem {...item} />)}
//       </PanelSection>
//     </div>
//   );
// }

const AboutPage = () => {
  const { headerStyle } = useStyles();

  return (
    <div className={styles.AboutPage} data-testid="AboutPage">
      <SEOHeaders
        title="Our Menu - Cornelio's Steakhouse"
        description="Delight your taste buds with our delectable steakhouse menu at Cornelio's Steakhouse. Indulge in premium cuts, perfectly grilled to perfection, and discover a symphony of flavors. Experience a culinary journey like no other, where every dish showcases the artistry of steak. Join us for an unforgettable dining experience."
        keywords="steakhouse menu, premium cuts, grilled steaks, culinary journey, steakhouse dining, exquisite flavors, mouthwatering dishes, Cornelio's Steakhouse, dining experience."
      />
      <AboutBanner />
      <ContentContainer>
        <div className={styles.MenuContainer}>
          <h2 style={headerStyle}>Our Menu</h2>
          <div className={styles.MenuImages}>
            <img src={menuOne} alt="Menu 1" />
            <img src={menuTwo} alt="Menu 2" />
            <img src={menuThree} alt="Menu 3" />
            <img src={menuFour} alt="Menu 4" />
          </div>
        </div>
      </ContentContainer>
    </div>
  )
};

export default AboutPage;
