import React from 'react';
import { createRoot } from 'react-dom/client';
import { hydrate } from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const RenderedApp = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

const container = document.getElementById("root");
const root = createRoot(container);
if(container.hasChildNodes()) {
  hydrate(<RenderedApp />, container)
} else {
  root.render(<RenderedApp />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
