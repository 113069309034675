import TemplateRoutes from "./components/TemplateRoutes/TemplateRoutes";
import ThemeContext, { themeDefaults } from "./contexts/ThemeContext";
import { ErrorBoundary, Provider } from "@rollbar/react";
import { rollbarConfig } from './constants/rollbarConfig';
import { ToastContainer } from 'react-toastify';

import logoWhite from './CornelioWhite.png';
import logoBlack from './CornelioBlack.png';

const themeSettings = {
  ...themeDefaults,
  primaryColor: 'black',
  secondaryColor: 'rgb(255, 247, 217)',
  primaryFont: 'Playfair Display SC, serif',
  secondaryFont: 'Montserrat, sans-serif',
  businessName: 'Cornelio\'s Steak House Corporation',
  phoneNumber: '909-936-7666',
  email: 'info@corneliossteakhouse.com',
  address: '11400 4th St STE 101, Rancho Cucamonga CA 91730',
  testimonialColors: {
    ...themeDefaults.testimonialColors,
    cardBackgroundColor: 'rgb(255, 252, 241)',
  },
  whiteLogo: logoWhite,
  coloredLogo: logoBlack,
  mapParams: {
    ...themeDefaults.mapParams,
    query: 'Cornelio\'s Steak House + Rancho Cucamonga, California',
  }
  // primaryColor: 'orange',
  // secondaryColor: 'blue',
  // etc. etc. etc.
}

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <ThemeContext.Provider value={themeSettings}>
            <TemplateRoutes />
          </ThemeContext.Provider>
        </ErrorBoundary>
      </Provider>
    </div>
  );
}

export default App;
